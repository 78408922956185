import type {
  NutrientOption,
  ScoreSubcategory,
  NutrientStatsByRecipient,
} from "../../@types";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import api from "../../utils/api/api";
import { toast } from "react-toastify";

interface NutrientStatsByRecipientContextInterface {
  loadingStats: boolean;
  loadingNutrients: boolean;
  stats: NutrientStatsByRecipient[];
  nutrients: NutrientOption[];
  selectedNutrient: NutrientOption | null;
  setSelectedNutrient: (nutrient: NutrientOption) => void;
  selectedScoreSubCategory: null | number;
  setSelectedScoreSubCategory: (scoreSubCategory: number | null) => void;
  scoreSubCategories: ScoreSubcategory[];
  handleFetchStatsData: (nutrientId: number) => Promise<void>;
}

export const NutrientStatsByRecipientContext =
  createContext<NutrientStatsByRecipientContextInterface>(
    {} as NutrientStatsByRecipientContextInterface
  );

export const NutrientStatsByRecipientProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const authStore = useSelector((state: RootState) => state.auth);

  const [loadingStats, setLoadingStats] = useState(true);
  const [loadingNutrients, setLoadingNutrients] = useState(true);

  const [selectedNutrient, setSelectedNutrient] =
    useState<NutrientOption | null>(null);
  const [selectedScoreSubCategory, setSelectedScoreSubCategory] = useState<
    null | number
  >(null);

  const [stats, setStats] = useState<NutrientStatsByRecipient[]>([]);
  const [nutrients, setNutrients] = useState<NutrientOption[]>([]);
  const [scoreSubCategories, setScoreSubCategories] = useState<
    ScoreSubcategory[]
  >([]);

  const handleFetchScoreSubCategories = useCallback(async () => {
    try {
      const { data } = await api.get(
        "/backoffice/metabolite/get-all-score-subcategories",
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      setScoreSubCategories(data);
    } catch {
      toast.error("Error on fetch score categories");
    }
  }, [authStore.token]);

  const handleFetchStatsData = useCallback(
    async (nutrientId: number) => {
      try {
        setLoadingStats(true);
        const queryParams = selectedScoreSubCategory
          ? `scoreSubCategory=${selectedScoreSubCategory}`
          : "";
        const { data } = await api.get(
          `backoffice/nutrient-recommendation/stats/${nutrientId}?${queryParams}`,
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );

        const mappedStats = data.map((nutrient: any) => ({
          id: nutrient.id,
          normalizedScore: nutrient.normalizedPercentageScore,
          absoluteScore: nutrient.score,
          dosageWeighted: nutrient.dosageWeightedAverage,
          biologicalForm: nutrient.bestBiochemicalFormName,
          metabolites: nutrient.metabolites.map((metabolite: any) => ({
            id: metabolite.id,
            name: metabolite.name,
            score: metabolite.score,
            level: metabolite.level,
          })),
        }));

        setStats(mappedStats);
      } catch (err) {
        setStats([]);
        console.log(err);
      } finally {
        setLoadingStats(false);
      }
    },
    [authStore.token, selectedScoreSubCategory]
  );

  const handleFetchNutrientOptions = useCallback(async () => {
    try {
      const { data: nutrients } = await api.get(
        "backoffice/nutrient-recommendation/nutrients",
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      setSelectedNutrient(nutrients[0]);
      setNutrients(nutrients);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingNutrients(false);
    }
  }, [authStore.token]);

  useEffect(() => {
    if (authStore.token) {
      handleFetchNutrientOptions();
      handleFetchScoreSubCategories();
    }
  }, [
    authStore.token,
    handleFetchNutrientOptions,
    handleFetchScoreSubCategories,
  ]);

  useEffect(() => {
    if (authStore.token && selectedNutrient) {
      handleFetchStatsData(selectedNutrient.id);
    }
  }, [authStore.token, handleFetchStatsData, selectedNutrient]);

  return (
    <NutrientStatsByRecipientContext.Provider
      value={{
        selectedNutrient,
        setSelectedNutrient,
        loadingNutrients,
        loadingStats,
        stats,
        nutrients,
        scoreSubCategories,
        selectedScoreSubCategory,
        setSelectedScoreSubCategory,
        handleFetchStatsData,
      }}
    >
      {children}
    </NutrientStatsByRecipientContext.Provider>
  );
};

export const useNutrientStatsByRecipient = () => {
  return useContext(NutrientStatsByRecipientContext);
};
